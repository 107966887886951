import React from 'react'
import { Helmet } from 'react-helmet'
import AboutUsContent from '../components/AboutUs/AboutUs'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { ABOUT_US as ABOUT_US_LINK } from '../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'About Us',
    url: ABOUT_US_LINK,
  },
]

const AboutUs: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Read Payments and Withdrawls FAQs on Mega Mobile App"
        description="Get to know about our vision, mission, core values and more. Click to read about how Mega is on a journey to redefine real money gaming! "
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <AboutUsContent />
    </Layout>
  )
}

export default AboutUs
